import React from "react";
import { Container, Image } from "react-bootstrap";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import CodeOutlinedIcon from "@material-ui/icons/CodeOutlined";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const experiences = [
  {
    title: "👨‍💻 Mickaël TORDJMAN - Développeur Fullstack JS",
    subtitle: "Freelance",
    content: [
      "🚀 Spécialisé en développement FullStack Typescript/Javascript, j'accompagne mes clients dans la conception et le développement de leurs plateformes, en mettant à profit mon expertise technique et ma capacité à m’intégrer rapidement dans des équipes existantes.",
      "Grâce à mes expériences variées (early stage à scale-up), je m’adapte à la phase de votre projet afin de vous conseiller de manière optimale.",
    ],
    icon: <CheckCircleOutlineIcon className="animated infinite pulse" />,
    iconBackground: "linear-gradient(#201823, #402b55)",
    contentStyle: { background: "#6E41C6", color: "#fff" },
    contentArrowStyle: { borderRight: "7px solid #424242" },
  },
  {
    date: "Juin 2021 - Aujourd'hui",
    title: "Manhaj - EdTech",
    subtitle: "Paris 1er",
    project: "Manhaj - Apprendre autrement",
    content: [
      "🚀 Implémentation d’un LMS complet: Gestion de contenu/cours, progression utilisateur, quiz ...",
      "🧑🏻‍💻 CTO as a Service: Conseil stratégique, collaboration avec l’équipe produit afin de définir les priorités techniques (de la planification des sprints à la mise en production des livrables)",
      "⚙️ Amélioration des process CI/CD et infra: Transition PaaS (Heroku) vers AWS EKS, pipelines, mise en place du monitoring (Datadog)",
      "💪 Recrutement et accompagnement d’une équipe tech sur les sprints",
    ],
    technologies: [
      "React.Js",
      "Node.Js",
      "Typescript",
      "TypeGraphQL/Apollo",
      "SQL/NoSQL (MariaDB/MongoDB)",
      "Strapi (Headless cms)",
      "Tests unitaires Jest",
      "Tests E2E Cypress",
      "Infra AWS (EKS/Docker)",
      "CI/CD Github Actions",
      "Datadog",
    ],
    icon: <WorkOutlineIcon />,
    iconBackground: "linear-gradient(#402b55, #4d3498)",
  },
  {
    date: "Juin 2023 - Mars 2024",
    title: "Ministère des Armées",
    subtitle: "Paris 15ème",
    project: "Projet OSCARS - SIRH",
    content: [
      "🚀 Développement d’un produit SIRH from scratch afin de gérer les formations des militaires",
      "🔐 Accompagnement sur les choix technologiques/architecture en tenant compte des contraintes de sécurité imposées",
      "🎨 Développement d’un Design System from scratch (Atomic Design/Storybook)",
      "💪 Accompagnement de profils juniors",
    ],
    technologies: [
      "React.Js",
      "Node.Js",
      "Express.js",
      "PostgreSQL",
      "Storybook",
      "SASS/SCSS",
      "Tests unitaires Jest",
      "Tests E2E Cypress",
      "Infra PaaS (Docker)",
      "CI/CD Gitlab",
    ],
    icon: <WorkOutlineIcon />,
    iconBackground: "linear-gradient(#402b55, #4d3498)",
  },
  {
    date: "Juin 2020 - Mars 2023",
    title: "Epsor - FinTech",
    subtitle: "Paris 2ème",
    project:
      "Epsor - L’alternative aux solutions d’épargne salariale et d’épargne retraite traditionnelles !",
    content: [
      "🚀 Intégré au sein des sprints (méthode agile/scrum) pour le développement de features diverses et variées sur une stack essentiellement TypeScript.",
      "🧑🏻‍💻 Développement de différents microservices selon le pattern d'architecture 'Event-Sourcing' (Apache Kafka) pour un produit d’épargne salariale",
      "⚙️ Implémentation de workflow Uber Cadence (GoLang) pour la gestion de transactions financières",
      "🧪 Tests unitaires Jest (TDD)",
      "📈 Mise en place de la méthodologie BDD (Cucumber/Gherkin/Cypress)",
    ],
    technologies: [
      "Typescript/Javascript",
      "React.JS",
      "Node.JS",
      "Event-Sourcing (Apache Kafka)",
      "Go",
      "GraphQL",
      "Next.JS",
      "Microservices (Docker/K8s)",
      "Jest",
      "Tests E2E Cypress",
      "SQL/NoSQL (MariaDB/MongoDB)",
      "AWS",
      "Docker",
      "CI/CD Gitlab",
    ],
    icon: <WorkOutlineIcon />,
    iconBackground: "linear-gradient(#402b55, #4d3498)",
  },
  {
    date: "2018 - 2021",
    title: "Paris 17ème",
    subtitle: "42",
    content: [
      "Pédagogie innovante en peer-to-peer: apprentissage par projets concrets entre étudiants",
      "Tronc commun d'une année intensive orienté système et algorithmes (C/C++) suivi d'une spécialisation en Développement Web (Fullstack JS)",
    ],
    icon: <SchoolOutlinedIcon />,
    iconBackground: "linear-gradient(#4d3498, #455eba)",
    image: "img/42.png",
  },
  {
    date: "2010",
    title: "Autodidacte",
    content: [
      "Passionné par la programmation informatique depuis toujours, je me forme en autonomie depuis le jeune âge jusqu’aujourd’hui",
      "Auto-formation continue: Fort attrait pour la veille technologique et l’acquisition de nouvelles compétences",
    ],
    icon: <CodeOutlinedIcon />,
    iconBackground: "#455eba",
  },
];

function ProSection({ refProSection }) {
  return (
    <Container className="nunito" ref={refProSection}>
      <VerticalTimeline className="vertical-timeline-custom-line">
        {experiences.map((exp, index) => (
          <VerticalTimelineElement
            key={index}
            date={exp.date}
            contentStyle={exp.contentStyle}
            contentArrowStyle={exp.contentArrowStyle}
            iconStyle={{ background: exp.iconBackground, color: "#fff" }}
            icon={exp.icon}
          >
            {exp.image && (
              <Image className="icon42_pro mb-2" src={exp.image} alt="logo42" />
            )}
            <h3 className="vertical-timeline-element-title nunito">
              {exp.title}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              {exp.subtitle}
            </h4>
            {exp.project && <p className="font-italic">{exp.project}</p>}
            {exp.content.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
            {exp.technologies && (
              <>
                <p>🚀 Technologies :</p>
                <ul>
                  {exp.technologies.map((tech, i) => (
                    <li key={i}>{tech}</li>
                  ))}
                </ul>
              </>
            )}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </Container>
  );
}

export default ProSection;
