import React from "react";
import Tilt from "react-tilt";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as MaltSvg } from "./malt.svg";

function BottomSection() {
  return (
    <Container className="mt-5 mb-3 p-4 background-assembly rounded">
      <Row>
        <Col sm={6} className="d-flex justify-content-center flex-column">
          <Tilt className="Tilt" options={{ max: 25 }}>
            <h1 style={{ color: "#007bff" }} className="nunito text-center">
              Contactez-moi
            </h1>
          </Tilt>
        </Col>
        <Col sm={6}>
          <Row className="mb-3 align-items-center">
            <Tilt className="Tilt" options={{ max: 25 }}>
              <div className="icon-container">
                <MaltSvg className="contact-icon" />
              </div>
            </Tilt>
            <a href="https://www.malt.fr/profile/mickaeltordjman">
              <p className="fontSizeContact nunito ml-3 mt-3">
                @mickaeltordjman - Malt
              </p>
            </a>
          </Row>
          <Row className="mb-3 align-items-center">
            <Tilt className="Tilt" options={{ max: 25 }}>
              <FontAwesomeIcon
                className="purple contact-icon"
                icon={faEnvelopeOpenText}
                size="4x"
              />
            </Tilt>
            <a href="mailto:mickael.tdj@gmail.com">
              <p className="fontSizeContact nunito ml-3 mt-3">
                mickael.tdj@gmail.com
              </p>
            </a>
          </Row>
          <Row className="mb-3 align-items-center">
            <Tilt className="Tilt" options={{ max: 25 }}>
              <FontAwesomeIcon
                className="purple contact-icon"
                icon={faLinkedin}
                size="4x"
              />
            </Tilt>
            <a href="https://www.linkedin.com/in/mickaeltdj">
              <p className="fontSizeContact nunito ml-3 mt-3">@mickaeltdj</p>
            </a>
          </Row>
          <Row className="align-items-center">
            <Tilt className="Tilt" options={{ max: 25 }}>
              <FontAwesomeIcon
                className="purple contact-icon"
                icon={faGithubSquare}
                size="4x"
              />
            </Tilt>
            <a href="https://www.github.com/micktdj">
              <p className="fontSizeContact nunito ml-3 mt-3">@micktdj</p>
            </a>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default BottomSection;
