import React, { useRef } from "react";
import Fade from "react-reveal/Fade";
import TopSection from "./TopSection";
import ProSection from "./ProSection";
import BottomSection from "./BottomSection";

function App() {
  const refProSection = useRef(null);
  return (
    <>
      <Fade>
        <TopSection refProSection={refProSection} />
      </Fade>
      <Fade>
        <ProSection refProSection={refProSection} />
      </Fade>
      <Fade>
        <BottomSection />
      </Fade>
    </>
  );
}

export default App;
