import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import TypeIt from "typeit";
import GlitchClip from "react-glitch-effect/core/Clip";
import Arrow from "./Arrow";
import Particles from "react-particles-js";
import params from "./particlesjs-config.json";

function TopSection({ refProSection }) {
  const [isDisabled, setDisabled] = useState(true);
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const timer1 = setTimeout(() => setDisabled(false), 4000);
    new TypeIt("#name", {
      speed: 150,
    })
      .type("Mickaël Tordjman")
      .pause(3000)
      .exec(() => {
        setShowArrow(true);
      })
      .go();
    new TypeIt("#web", {
      speed: 110,
      startDelay: 5000,
    })
      .type("Développeur FullStack")
      .go();
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <Container fluid className="bgAuth">
      <Container className="center" style={{ zIndex: "2000" }}>
        <Container className="d-flex flex-column justify-content-center">
          <Row>
            <Col className="text-center mb-4">
              <GlitchClip disabled={isDisabled} duration="3s">
                <Image
                  src="https://public-mickaeltordjman.s3.eu-west-3.amazonaws.com/cv2-rounded.png"
                  className={isDisabled ? "hidden cvImg" : "cvImg"}
                />
              </GlitchClip>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <p id="name" className="white display-4 fontPolice" />
              <p id="web" className="white mt-4 display-4 fontPolice" />
            </Col>
          </Row>
          <Arrow refProSection={refProSection} showArrow={showArrow} />
        </Container>
      </Container>
      <Particles
        params={params}
        style={{ position: "absolute", top: "0", right: "0" }}
      />
    </Container>
  );
}

export default TopSection;
