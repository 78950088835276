import React from 'react'
import { Fade } from 'react-reveal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap'

function Arrow ({ showArrow, refProSection }) {
  const handleScroll = (event) => {
    if (refProSection.current && showArrow === true) {
      refProSection.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  if (showArrow) {
    return (
      <Fade top big>
        <Row className='mt-2'>
          <Col className='text-center'>
            <FontAwesomeIcon icon={faAngleDoubleDown} onClick={handleScroll} size='4x' className='pointer white animated infinite pulse' />
          </Col>
        </Row>
      </Fade>
    )
  } else { return null }
}

export default Arrow
